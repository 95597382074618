import React from 'react'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Layout, SEO, Heading, Text, Categories } from '../components/index'
import { BackToTop, ArrowLeft, WaveBlue, Link as IconLink } from '../components/icons/index'
import { featuredProject } from '../queries/index' // eslint-disable-line

export default function PageTemplate(props) {
  const {
    data: { mdx },
  } = props
  const { title, featuredImage, categories, link } = mdx.frontmatter

  return (
    <Layout id="top" bg="tertiary">
      <SEO title={title || 'Project'} />

      <div className="grid pt0-xs pt6-md">
        <div className="row">
          <div className="col8">
            <div className="row row-xs-middle">
              <Link to="/projects/" style={{ marginRight: '2.4rem' }}>
                <ArrowLeft />
              </Link>
              <Heading level="1" size="large" align="left">
                {title || 'Project'}
              </Heading>
            </div>
          </div>
        </div>

        <div className="row row-xs-middle pb1-xs">
          <div className="col col-9-xs col-0-xs-offset col-6-md col-2-md-offset">
            {categories && categories.length > 0 && <Categories categories={categories} />}
          </div>
          {link && (
            <div className="col col-3-xs col-2-md">
              <Text align="right" size="g2" transform="capitalize" link="underline">
                <a href={link}>
                  visit <IconLink />
                </a>
              </Text>
            </div>
          )}
        </div>

        <div className="row pb3-xs pd3-md">
          <div className="col8">
            {featuredImage && (
              <>
                <div style={{ display: 'block', position: 'relative', zIndex: '10' }}>
                  <Img fluid={featuredImage.childImageSharp.fluid} />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col8">
            {mdx.body ? (
              <div>
                <MDXRenderer>{mdx.body}</MDXRenderer>
              </div>
            ) : (
              <Text size="medium">Content could not be loaded.</Text>
            )}
          </div>
        </div>
        {mdx.body && (
          <div className="row">
            <div className="col8 pt3-xs pb3-xs pt6-md pb6-md">
              <WaveBlue />
            </div>
            <div className="col8">
              <BackToTop to="#top" />
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query SingleProjectQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      ...featuredProject
    }
  }
`
